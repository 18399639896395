import { useEffect, useState } from 'react';

const useWindowScroll = () => {
  const [size, setSize] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      setSize(window.pageYOffset);
    };

    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return size;
};

export default useWindowScroll;

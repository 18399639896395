import React, { ReactNode, useContext, useEffect, useState } from "react";
import Head from "next/head";
import { SEOProps } from "@/utils/types";
import useWindowScroll from "@/hooks/useWindowScroll";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { GlobalContext } from "./context/Global";
import Cookies from "js-cookie";
import { functionalCookie } from "@/utils/helpers";
import FooterTemplate from "./FooterTemplate";

const CookieModal = dynamic(() => import("./CookieModal"));
const SEO = dynamic(() => import("./SEO"));
const Menu = dynamic(() => import("./Menu"));
const Banner = dynamic(() => import("./Banner"));


interface LayoutProps extends SEOProps {
  children: ReactNode;
  menuType?: string;
  footerType?: string;
  banner?: boolean;
  bannerClass?: string;
  bannerPage?: string;
  notFixedHeader?: boolean;
  absolute?: boolean;
  ribbonHide?: boolean;
}

const Layout = ({
  children,
  title,
  description,
  ogTitle,
  ogDescription,
  ogImage,
  menuType = "light",
  footerType,
  banner,
  bannerClass,
  bannerPage,notFixedHeader, absolute,ribbonHide
}: LayoutProps) => {
  const router = useRouter();
  const scroll = useWindowScroll();
  const { isCookiePopup, setCookiePopup } = useContext<any>(GlobalContext);
  const [cookie, setCookie] = useState(false);
  const [isFunctional, setIsFunctional] = useState(false);
  const [isMarketing, setIsMarketing] = useState(false);
  const [expand, setExpand] = useState(null);





  const setFuntionalCookie = () => {
    functionalCookie?.map((item: any) => Cookies.set(item, "1"));

    setIsFunctional(true);
  };

  const setMarketingCookies = () => {
    setIsMarketing(true);
  };
  const setCookieFn = (val: any) => {
    if (val === "All") {
      setFuntionalCookie();
      setMarketingCookies();
    }
  };
  const removeCookieFn = (val: any) => {
    if (val === "All") {
      removeFunctionalCookie();
      removeMarketingCookie();
      setIsMarketing(false);
      setIsFunctional(false);
    }
  };
  const handleAccept = () => {
    setCookieFn("All");
    Cookies.set("cookieconsent", "Accept");
    setCookie(false);
  };


  const handleReject = () => {
    // Cookies.remove;
    removeCookieFn("All");
    Cookies.set("cookieconsent", "Reject");
    setCookie(false);
  };
  const removeFunctionalCookie = () => {
    functionalCookie?.map((item: any) => Cookies.remove(item));
  };
  const removeMarketingCookie = () => {
    const marketingScriptId = [
      "facebook-pixel",
      "facebook-pixel1.1",
      "linkedinId1",
      "linkedinId2",
      "linkedin2.1",
      "LinkedInLead1",
      "LinkedInLead2",
      "LinkedInLead2.1",
      "googleAds",
      "googleAds2",
      "gistchat",
      "metaPixel",
      "metapixel1",
    ];
    marketingScriptId?.map((item) => {
      const scriptToRemove = document.getElementById(item);
      if (scriptToRemove) {
        scriptToRemove.parentNode?.removeChild(scriptToRemove);
        console.log("Script removed");
      } else {
        console.log("Script not found");
      }
    });
  };
  const submitHandler = (val:any) => {
    if(val ==='selected'){
      if (isFunctional && isMarketing) {
        handleAccept();
      } else if (isFunctional && !isMarketing) {
        setFuntionalCookie();
        removeMarketingCookie();
        const preferences = { isFunctional: "1", isMarketing: "0" };
        const preferencesString = JSON.stringify(preferences);
        Cookies.set("cookieconsent", "Preferences");
        Cookies.set("Preferences", preferencesString);
        // remove ismarketing code need to add .
      } else if (!isFunctional && isMarketing) {
        setMarketingCookies();
        removeFunctionalCookie();
        const preferences = { isFunctional: "0", isMarketing: "1" };
        const preferencesString = JSON.stringify(preferences);
        Cookies.set("cookieconsent", "Preferences");
        Cookies.set("Preferences", preferencesString);
      } else {
        removeMarketingCookie();
        removeFunctionalCookie();
      }
    }else if(val ==='All'){
      handleAccept();
    }else if(val ==='Deny'){
      handleReject();
    } 


    setCookiePopup(false);
    setCookie(false);
  };


  return (
    <>
      <Head>
       <meta content='width=device-width, initial-scale=1, user-scalable=1, minimum-scale=1, maximum-scale=5' name='viewport'/> 
      </Head>
      <SEO
        title={title}
        description={description}
        ogTitle={ogTitle}
        ogDescription={ogDescription}
        ogImage={ogImage}
        canonical={`${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath}`}
      />
      <header>
        <Menu menuType={menuType} notFixedHeader={notFixedHeader} absolute={absolute} />
      </header>
      <main>{children}</main>
      <footer className={footerType === "banner" ? "footer-banner" : ""}>
        {/* <Footer /> */}
        <FooterTemplate />

      </footer>
      {banner && !cookie && !ribbonHide && (
        <Banner
          page={bannerPage}
          scrollPos={scroll}
          setExpand={setExpand}
          expand={expand}
          className={
              (expand === true ?  
              `${bannerClass} banner-slide d-none` : (scroll > 702&&expand==null) 
              ? `${bannerClass} banner-slide d-none`:`${bannerClass} d-block banner-opacity`)
          }
        />
      )}
      {isCookiePopup && (
        <CookieModal
          show={isCookiePopup}
          onHide={() => setCookiePopup(false)}
          isFunctional={isFunctional}
          setIsFunctional={setIsFunctional}
          isMarketing={isMarketing}
          setIsMarketing={setIsMarketing}
          submitHandler={submitHandler}
        />
      )}
    </>
  );
};

export default Layout;

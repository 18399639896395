import { Button, Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import axios from "axios";
import Link from "next/link";
import Image from "next/image";

const NewsletterFormTemplate = () => {
  const [loading, setLoading] = useState<number>(0);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
    .trim()
    .max(50, "Email can't be longer than 50 characters")
    .required("Required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Email has an invalid format")
    .test('no-spaces', 'Email should not contain spaces', (value?: string) => !/\s/.test(value || ''))
    .test('no-consecutive-dots', 'Email should not contain consecutive dots', (value?: string) => !/\.\./.test(value || ''))
    .test('no-trailing-dot', 'Email should not end with a dot', (value?: string) => {
      if (!value) return true; // Skip validation if value is empty
      const [localPart] = value.split('@');
      return !localPart.endsWith('.');
    })
    .test('valid-domain', 'Invalid domain format', (value?: string) => {
      if (!value) return true; // Skip validation if value is empty
      const domain = value.split('@')[1];
      if (!domain) return false;
      // Validate domain format with generalized pattern
      return /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(domain);
    })
    .test('no-leading-dot-in-domain', 'Domain should not start with a dot', (value?: string) => {
      if (!value) return true;
      const domain = value.split('@')[1];
      if (!domain) return false;
      return !domain.startsWith('.');
    })
    .test('valid-TLD', 'Top-level domain should be valid', (value?: string) => {
      if (!value) return true;
      const domain = value.split('@')[1];
      if (!domain) return false;
      const tld = domain.split('.').pop(); // Get the TLD (last part of the domain)
      return !!tld && tld.length >= 2 && tld.length <= 63;
    })
    .test('no-XSS-characters', 'Email contains invalid characters', (value?: string) => {
      const xssPattern = /[<>]/;
      return value ? !xssPattern.test(value) : true;
    })
    .test('no-SQL-injection', 'Email contains SQL injection pattern', (value?: string) => {
      if (!value) return true;
      // Allow `xn--` specifically in the domain part of the email
      const [localPart, domainPart] = value.split('@');
      const sqlInjectionPatternLocalPart = /(\b(SELECT|INSERT|UPDATE|DELETE|DROP|ALTER|OR|AND)\b|'|"|;|=|--)/i;
      const sqlInjectionPatternDomainPart = /(\b(SELECT|INSERT|UPDATE|DELETE|DROP|ALTER|OR|AND)\b|'|"|;|=)/i;
      return (
        !sqlInjectionPatternLocalPart.test(localPart) &&
        (!domainPart || !sqlInjectionPatternDomainPart.test(domainPart))
      );
    })
    .test('no-disposable-email', 'Disposable email addresses are not allowed', (value?: string) => {
      if (!value) return true;
      const domain = value.split('@')[1];
      const disposableDomains = [
        "tempmail.com", 
        "10minutemail.com", 
        "guerrillamail.com", 
        "mailinator.com", 
        "yopmail.com"
      ];
      return !disposableDomains.includes(domain);
    }),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      Type: "Newsletter",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      setLoading(1);
      const trimmedValues = {
        ...values,
        email: values.email.trim(),
      };
      await axios
        .post(
          `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/api/newsletters`,
          { data: trimmedValues },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_TOKEN}`,
            },
          }
        )
        .then((res) => {
          if (res?.data?.data?.id) {
            setLoading(2);
          } else {
            setLoading(3);
          }
        })
        .catch(() => {
          setLoading(3);
        });
    },
  });

  const loadStatus = () => {
    if (loading === 1) {
      return <p>Submitting data. Please wait...</p>;
    } else if (loading === 2) {
      return (
        <p>
          Thanks for subscribing! Get ready for a front-row
          <br />
          seat to {`Cubet's`} latest updates,delivered straight to
          <br />
          your inbox. Stay tuned!
        </p>
      );
    } else {
      return <p>Something went wrong. Try again.</p>;
    }
  };

  return (
    <div className="newsletter-form">
      {loading === 0 ? (
        <Form onSubmit={formik.handleSubmit}>
          <div className="mb-0">
            <div className="newsletter-inputs">
              <input
                type="text"
                name="email"
                placeholder="Enter your email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                autoComplete="none"
                className="form-control"
              />
              {/* <input type="button" className="btn btn-light bg-white" value="Submit"></input> */}
             <Button type="submit" className="btn btn-light bg-white footer-email">Submit</Button>
            </div>
          </div>
          {formik.errors.email ? (
            <div className="invalid-feedback d-block text-start fs-12 my-1">
              {formik.errors.email}
            </div>
          ) : (
            null
          )}
          {/* <Button type="submit">Submit</Button> */}
          <div className="d-none d-md-flex align-items-center social-mob mb-25-rem-fixed mt-3">
            <span className="sm-title">Our Socials</span>
            <Link prefetch={false}
              href="https://www.facebook.com/CubetTech/"
              target="_blank"
              rel="nofollow"
            >
              <Image
                src="/assets/icons/facebook.svg"
                width={24}
                height={24}
                alt="facebook"
                className="img-fluid"
              />
            </Link>
            <Link prefetch={false}
              href="https://twitter.com/CubetTech"
              target="_blank"
              rel="nofollow"
            >
              <Image
                src="/assets/icons/twitter-new1.png"
                width={20}
                height={20}
                alt="twitter"
                className="img-fluid"
              />
            </Link>
            <Link prefetch={false}
              href="https://www.linkedin.com/company/cubet-technologies"
              target="_blank"
              rel="nofollow"
            >
              <Image
                src="/assets/icons/linkedin.svg"
                width={24}
                height={24}
                alt="linkedin"
                className="img-fluid"
              />
            </Link>
            <Link prefetch={false}
              href="https://www.instagram.com/cubettechnolabs/"
              target="_blank"
              rel="nofollow"
            >
              <Image
                src="/assets/icons/instagram.svg"
                width={24}
                height={24}
                alt="instagram"
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="d-flex d-md-none align-items-center social-mob mb-25-rem-fixed mt-3">
            <span className="sm-title">Our Socials</span>
            <Link prefetch={false}
              href="https://www.facebook.com/CubetTech/"
              target="_blank"
              rel="nofollow"
            >
              <Image
                src="/assets/icons/facebook.svg"
                width={24}
                height={24}
                alt="facebook"
                className="img-fluid"
              />
            </Link>
            <Link prefetch={false}
              href="https://twitter.com/CubetTech"
              target="_blank"
              rel="nofollow"
            >
              <Image
                src="/assets/icons/twitter-new1.png"
                width={20}
                height={20}
                alt="twitter"
                className="img-fluid"
              />
            </Link>
            <Link prefetch={false}
              href="https://www.linkedin.com/company/cubet-technologies"
              target="_blank"
              rel="nofollow"
            >
              <Image
                src="/assets/icons/linkedin.svg"
                width={24}
                height={24}
                alt="linkedin"
                className="img-fluid"
              />
            </Link>
            <Link prefetch={false}
              href="https://www.instagram.com/cubettechnolabs/"
              target="_blank"
              rel="nofollow"
            >
              <Image
                src="/assets/icons/instagram.svg"
                width={24}
                height={24}
                alt="instagram"
                className="img-fluid"
              />
            </Link>
          </div>
          <Row>
          <Col md={6} lg={7} className="d-block d-md-none">
            <Image
              src="/assets/icons/cubet.png"
              width={82}
              height={20}
              priority={false}
              alt="cubet"
              className="img-fluid logo-white"
            />
            <div className="mb-3 address-boxes">
              <Link prefetch={false}
                href="/contact-us#global"
                className="d-flex align-items-center hover-undeline"
              >
                <Image
                  src="/assets/icons/atlanta.svg"
                  width={30}
                  height={30}
                  priority={false}
                  alt="Cubet US Address"
                  className="img-fluid"
                />
                <p className="ms-2 normal-text text-white mb-0 country-names">Atlanta, US</p>
              </Link>
              <Link prefetch={false}
                href="/contact-us#global"
                className="d-flex align-items-center hover-undeline"
              >
                <Image
                  src="/assets/icons/london-flag.svg"
                  width={30}
                  height={30}
                  priority={false}
                  alt="Cubet IN Address"
                  className="img-fluid"
                />
                <p className="ms-2 normal-text text-white mb-0 country-names">London, UK</p>
              </Link>
              <Link prefetch={false}
                href="/contact-us#global"
                className="d-flex align-items-center hover-undeline"
              >
                <Image
                  src="/assets/icons/india-flag.svg"
                  width={30}
                  height={30}
                  priority={false}
                  alt="Cubet IN Address"
                  className="img-fluid"
                />
                <p className="ms-2 normal-text text-white mb-0 country-names">Kochi, India</p>
              </Link>
            </div>
           
            
            
           
          </Col>
          </Row>
        </Form>
      ) : (
        loadStatus()
      )}
    </div>
  );
};

export default NewsletterFormTemplate;

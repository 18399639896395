/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */

import Link from "next/link";
import Image from "next/image";

const RatingTemplate = () => {
  return (
    <div className="logos-footer d-flex gap-3 align-items-center text-nowrap">
      <span
        rel="nofollow" className="pe-none"
      >
        <Image
          src="/assets/icons/nasscom.svg"
        //   width={""}
        //   height={40}
        width={40}
        height={40}
        loading="lazy"
        //   priority={false}
          alt="rating"
        //   className="img-fluid"
        />
      </span>
      <Link prefetch={false}
        href="https://clutch.co/profile/cubet"
        target="_blank"
        rel="nofollow"
      >
        <Image
          src="/assets/icons/clutch.svg"
          width={40}
          height={40}
          loading="lazy"
        //   width={47}
        //   height={40}
        //   priority={false}
          alt="rating"
        //   className="img-fluid"
        />
      </Link>
      <Link prefetch={false}
        href="https://www.goodfirms.co/company/cubet-techno-labs"
        target="_blank"
        rel="nofollow"
      >
        <Image
          width={40}
          height={40}
          loading="lazy"
          src="/assets/icons/good-firm.svg"
        //   width={82}
        //   height={40}
        //   priority={false}
          alt="rating"
        //   className="img-fluid"
        />
      </Link>
      
    </div>
  );
};

export default RatingTemplate;
